import MyComponentTypes from '../MyComponent/Types'
const MyLayoutHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyLayoutHelpers.Types, key, fnUI)
  },
  addTypesFromListLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListLazy(
      MyLayoutHelpers.Types,
      listWithKey
    )
  },
  addTypesFromListWithoutLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListWithoutLazy(
      MyLayoutHelpers.Types,
      listWithKey
    )
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  }
}

export default MyLayoutHelpers
