interface IConfigFilter {}

class ConfigFilter implements IConfigFilter {
  type: string = ''
  typeBSTv1: string = ''
  constructor(type: string, opts?: any) {
    this.type = type
    if (opts) {
      if (opts.typeBSTv1) {
        this.typeBSTv1 = opts.typeBSTv1
      }
    }
  }
  getBootstrapTableV1(value: any) {
    return { type: this.typeBSTv1, value: value }
  }
}

export default ConfigFilter
