import * as React from 'react'
import MyComponentTypes from '../MyComponent/Types'
const MyIconHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyIconHelpers.Types, key, fnUI)
  },
  addTypesFromListLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListLazy(
      MyIconHelpers.Types,
      listWithKey
    )
  },
  addTypesFromListWithoutLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListWithoutLazy(
      MyIconHelpers.Types,
      listWithKey
    )
  },
  Types: {
    _empty: MyComponentTypes.Types._empty,
    loading: (props: any) => {
      return (
        <i
          key='iconloading'
          className={`fa fa-spinner fa-spin ${
            props.className != null ? props.className : ''
          }`}
          style={props.style}
        />
      )
    }
  }
}

export default MyIconHelpers
