import { isString } from '../../helpers/Is'
import { logNoAnyType } from '../../helpers/Log'
import MyComponentTypes from './Types'
const MyComponentHelpers = {
  getMyComponentTypeFn(types: any, props: any) {
    let _type = ''
    // console.warn('getMyComponentTypeFn:', types, props)
    if (props && props.type && isString(props.type)) {
      _type = props.type.toLocaleLowerCase() || ''
    }
    if (types && types[_type]) {
      return types[_type]
    }
    logNoAnyType(`Component:${_type}`, props, types)
    if (types && types._default) {
      return types._default
    }
    if (types && types._empty) {
      return types._empty
    }
    return MyComponentTypes.Types._empty
  }
}

export default MyComponentHelpers
