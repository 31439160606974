import * as React from 'react'
import MyComponentHelpers from '../MyComponent/Helpers'
import MyHelpers from './Helpers'
function MyUI(props: any) {
  const MyTypeFn = MyComponentHelpers.getMyComponentTypeFn(
    MyHelpers.Types,
    props
  )
  return <MyTypeFn {...props} />
  // return MyTypeFn(props)
}

export default MyUI
