import { runFuntion } from '../helpers/Utils'

interface IConfigOnClick {}

class ConfigOnClick implements IConfigOnClick {
  constructor() {}
  static getOnClickFromMoreCell(
    fnList: any,
    data: any,
    configOnClick: any
  ): any {
    if (fnList) {
      if (configOnClick) {
        if (configOnClick.fnName) {
          let _args: any[] = []
          if (configOnClick.args && configOnClick.args.length > 0) {
            for (let i = 0; i < configOnClick.args.length; i++) {
              let _v = configOnClick.args[i]
              if (data && data.hasOwnProperty(_v)) {
                _v = data[_v]
              }
              _args.push(_v)
            }
          }
          return () => {
            runFuntion(fnList, configOnClick.fnName, _args)
          }
        }
      }
    }
    return null
  }
}

export default ConfigOnClick
