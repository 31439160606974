import * as React from 'react'
class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.warn('ErrorBoundary UI:', error, errorInfo, this.props)
  }
  render() {
    if (this.state.hasError) {
      console.warn('ErrorBoundary', this.props)
      return <div></div>
    }
    return this.props.children
  }
}

export default ErrorBoundary
