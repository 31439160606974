import { isArray, isString } from './Is'
import { mergeObjIfExist } from './Utils'

export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getAllParamOnUrl(url = window.location.href) {
  let _params = {}
  let _indexQuestionMark = url.lastIndexOf('?')
  try {
    if (_indexQuestionMark > -1) {
      let _folowQuestion = url.substring(_indexQuestionMark + 1, url.length)
      if (_folowQuestion) {
        let _arrParam = _folowQuestion.split('&')
        if (_arrParam) {
          for (let i = 0; i < _arrParam.length; i++) {
            let _rs = _arrParam[i].split('=')
            if (_rs && _rs.length > 1) {
              _params[_rs[0]] = decodeURIComponent(_rs[1])
            }
          }
        }
      }
    }
  } catch (error) {
    console.error('Error parse url', error)
  }
  return _params
}

export function updateUrlWithAllParams(
  allParams: object,
  url = window.location.href,
  opts = { updateUrlWithoutReload: true }
) {
  let _newUrl = url
  if (allParams) {
    let _indexQuestionMark = url.lastIndexOf('?')
    if (_indexQuestionMark == -1) {
      _indexQuestionMark = url.length
    }
    _newUrl =
      url.substr(0, _indexQuestionMark) +
      '?' +
      Object.keys(allParams)
        .map((v) => {
          return `${v}=${allParams[v]}`
        })
        .join('&')
    if (opts && opts.updateUrlWithoutReload) {
      setNewUrlWithoutReload(_newUrl)
    }
  }
  return _newUrl
}

export function clearParamOnUrl(
  name: string | string[],
  url = window.location.href
) {
  let _allPrams = getAllParamOnUrl(url)
  let _newUrl = url
  if (isString(name) && _allPrams && _allPrams[name as string]) {
    delete _allPrams[name as string]
    return updateUrlWithAllParams(_allPrams, url)
  } else if (isArray(name) && _allPrams) {
    for (let k of name as string[]) {
      if (_allPrams[k]) {
        delete _allPrams[k]
      }
    }
    return updateUrlWithAllParams(_allPrams, url)
  }
  // if (_allPrams && _allPrams[name]) {
  //   delete _allPrams[name]
  //   updateUrlWithAllParams(_allPrams, url)
  // }
  return _newUrl
}

export function keeponlyParams(names: string[], url = window.location.href) {
  let _allPrams = getAllParamOnUrl(url)
  if (names && names.length > 0) {
    let _arrRemove = []
    let _pK = Object.keys(_allPrams)
    for (let k of _pK) {
      if (names.indexOf(k) == -1) {
        _arrRemove.push(k)
      }
    }
    if (_arrRemove && _arrRemove.length > 0) {
      return clearParamOnUrl(_arrRemove, url)
    }
  }
  return url
}
export function updateParamOnUrl(
  name: string,
  value: string,
  url = window.location.href
) {
  let _allPrams = getAllParamOnUrl(url)
  let _newUrl = url
  if (_allPrams) {
    _allPrams[name] = value
    return updateUrlWithAllParams(_allPrams, url)
  }
  return _newUrl
}

export function setNewUrlWithoutReload(url: string) {
  if (url) {
    window.history.pushState({ path: url }, '', url)
  }
}

const LinkInfo: any = {
  usingHash: false,
  history: null
}

export const initLink = (info: any) => {
  mergeObjIfExist(LinkInfo, info)
  console.warn('initLink:', LinkInfo)
}
export const getHistory = () => {
  return LinkInfo.history
}
export const getIsUsingHash = () => {
  return LinkInfo.usingHash
}
export const openUrl = function (link: string, target: string = '_blank') {
  if (link) {
    if (link.startsWith('http')) {
      return window.open(link, target)
    } else if (link.indexOf('://') > -1) {
      return window.open(link, target)
    }
  }
  let _link = link
  //Tuy vao kieu blank hay self ma convert link theo hash khac nhau
  if (target === '_blank') {
    if (LinkInfo.usingHash === true) {
      _link = getHrefByUrlInHash(link)
    }
    return window.open(_link, target)
  } else {
    if (LinkInfo.usingHash === true) {
      _link = getLinkPushInHash(link)
    }
    return push(_link)
  }
}
export const push = (link: string) => {
  if (LinkInfo.history) {
    LinkInfo.history.push(link)
  } else {
    window.location.href = link
  }
}
export function getLinkPushInHash(url: string) {
  let _url = url
  if (url) {
    if (url.startsWith('/#/')) {
      _url = '/' + url.slice(3, url.length)
    } else if (url.startsWith('#/')) {
      _url = '/' + url.slice(2, url.length)
    }
    return _url
  }
  return _url
}
export function getHrefByUrlInHash(url?: string) {
  if (url) {
    if (url.startsWith('http')) {
      return url
    } else if (url.startsWith('/#/')) {
      return url
    } else if (url.startsWith('/')) {
      return `/#${url}`
    }
    return `/#/${url}`
  }
  return '#'
}
