import { json2Obj } from '../helpers/Json'
import { get, mergeObjIfExist } from '../helpers/Utils'
import { updateListInList } from '../helpers/Options'
import HConstant from '../helpers/Constants'
interface IOneScreenConfig {}

interface IOneScreenConfigOpts {
  defaultScreenConfig?: any
}

interface IOneScreenConfigTableOptions {
  sizePerPage?: number
}
interface IOneScreenConfigTableColsActions {
  show?: boolean
  listActions?: any[]
}
interface IOneScreenConfigTable {
  hasExpand?: boolean
  showDetails?: boolean
  showDetailsMulti?: boolean
  hasPagingRemote?: boolean
  tableProps?: object
  optionsTable?: IOneScreenConfigTableOptions
  colsActions?: IOneScreenConfigTableColsActions
}
interface IOneScreenConfigHeader {
  title?: string
  titleIcon?: string
  showProjectMenu?: boolean
  showProjectHeader?: boolean
}
interface IOneScreenConfigPage {
  title?: string
}
interface IOneScreenConfigDetail {
  multi?: boolean
}
interface IOneScreenConfigFilter {
  show?: boolean
  require4ShowData?: boolean //bat buoc phai search thi moi show data
  listInList?: string[] //danh sach field se tao options inlist
  data?: any[] //danh sach content filter
  listFieldTextByFieldOptionsInList?: any //dung cho viet add options listInList
}

const Key = {
  pathScreenConfig: 'Configs.ScreenConfig'
}
class OneScreenConfig implements IOneScreenConfig {
  _table: IOneScreenConfigTable = {}
  _header: IOneScreenConfigHeader = {}
  _page: IOneScreenConfigPage = {}
  _detail: IOneScreenConfigDetail = {}
  _filter: IOneScreenConfigFilter = {
    show: true,
    require4ShowData: false,
    listInList: [],
    data: []
  }
  _objFilterSearch: any = null
  _objScreenConfig: object
  _options: any
  constructor(opts?: IOneScreenConfigOpts) {
    this._objScreenConfig = {}
    this.initDefault(opts)
  }
  initDefault(opts?: IOneScreenConfigOpts) {
    this._table = {
      optionsTable: {
        sizePerPage: 20
      }
    }
    this._header = {}
    if (opts && opts.defaultScreenConfig) {
      this.parseFromScreenConfig(opts.defaultScreenConfig)
    }
  }
  parseFromOptions(options: any) {
    this._objScreenConfig = json2Obj(get(options, Key.pathScreenConfig), {})
    this._options = options
    this.parseFromScreenConfig(this._objScreenConfig)
  }
  parseFromScreenConfig(screenConfig: any) {
    mergeObjIfExist(this._page, get(screenConfig, HConstant.Key.Page))
    mergeObjIfExist(this._table, get(screenConfig, HConstant.Key.Table))
    mergeObjIfExist(this._header, get(screenConfig, HConstant.Key.Header))
    mergeObjIfExist(this._detail, get(screenConfig, HConstant.Key.Detail))
    mergeObjIfExist(this._filter, get(screenConfig, HConstant.Key.Filter))
  }
  parseFromListAndExtraData(list?: any[], extraData?: any) {
    console.warn('parseFromExtraData', list, extraData)
    this._filter.data = []
    this._filter.listInList = []
    if (this._objFilterSearch) {
      this._filter.data.push(this._objFilterSearch)
    }
    if (extraData && extraData.Columns) {
      let _fields = Object.keys(extraData.Columns)
      if (_fields && _fields.length > 0) {
        for (let k of _fields) {
          let _col = extraData.Columns[k]
          if (_col) {
            if (_col.Filter && _col.Filter.type) {
              //add listInList
              if (
                _col.Filter.optionKey &&
                _col.Filter.optionKey.indexOf('_InList') > -1
              ) {
                this._filter.listInList.push(
                  _col.Filter.optionKey.replace('_InList', '')
                )
              } else if (
                _col.Filter.optionKey &&
                (_col.Filter.optionKey.toLowerCase() == 'inlist' ||
                  _col.Filter.optionKey.toLowerCase().endsWith('_inlist') ==
                    true)
              ) {
                _col.Filter.optionKey = `${k}_InList`
                this._filter.listInList.push(k)
              }

              let _moreFilter = {}
              if (_col.Filter.more) {
                _moreFilter = json2Obj(_col.Filter.more, {})
              }
              let _objFilter = {
                type: _col.Filter.type,
                title: _col.Filter.title || _col.Header || k,
                optionKey: _col.Filter.optionKey,
                fieldName: k,
                fieldQuery: null,
                more: _moreFilter
              }
              this._filter.data.push(_objFilter)
            }
          }
        }
      }
    }
    updateListInList(this._options, this._filter.listInList, list)
  }
  static debug() {
    console.warn(OneScreenConfig)
  }
}

export default OneScreenConfig
