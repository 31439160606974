interface IOnePage {}

interface IOnePageOptions {}

class OnePage implements IOnePage {
  constructor(opts: IOnePageOptions) {
    if (opts) {
    }
  }
  getTableConfigBootstrapv1() {
    return {}
  }
}

export default OnePage
