import { isObject, isString } from 'src/helpers/Is'
import { validSearchText } from 'src/helpers/Text'

interface IConfigSearch {
  instant?: boolean
  noSymbol?: boolean
}

class ConfigSearch implements IConfigSearch {
  instant: boolean = false
  noSymbol: boolean = false
  constructor(config: any = {}) {
    this.instant = config.instant || false
    this.noSymbol = config.noSymbol || false
  }
  getFiltered(
    arr: any[],
    textSearch: string,
    opts: {
      onlyFields?: string[]
      excludeFields?: string[]
      configSearch?: any
    } = {}
  ) {
    if (arr && arr.length > 0) {
      return arr.filter((v: any) => {
        if (isObject(v)) {
          let _keys = Object.keys(v)
          let _result = false
          for (let k of _keys) {
            if (opts) {
              if (opts.onlyFields && opts.onlyFields.indexOf(k) == -1) {
                continue
              }
              if (opts.excludeFields && opts.excludeFields.indexOf(k) > -1) {
                continue
              }
            }

            if (isString(v[k])) {
              _result = validSearchText(
                v[k],
                textSearch,
                opts.configSearch || this
              )
            }
            if (_result === true) {
              return true
            }
          }
        } else if (isString(v)) {
          return validSearchText(v, textSearch, opts.configSearch || this)
        }
        return false
      })
    }
    return []
  }
  static test() {
    console.warn(ConfigSearch)
  }
}

export default ConfigSearch
