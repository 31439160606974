import { isFunction } from './Is'
import { randomHextColor } from './Color'
const FakeData = {
  getLorem() {
    return "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
  },
  getLoremWord() {
    return FakeData.getRandomWordOfString(FakeData.getLorem())
  },
  getLoremWithRandom(max: number = 20) {
    return new Array(max)
      .fill(0)
      .map(() => {
        return FakeData.getLoremWord()
      })
      .join(' ')
  },
  getRandomWordOfString(text: string) {
    let _arr = text.split(' ')
    let _total = _arr.length
    if (_arr.length > 0) {
      return _arr[FakeData.getNumberInt(0, _total - 1)]
    }
    return ''
  },
  getName() {},
  getNumberInt(min = 0, max = 1000) {
    return Math.floor(FakeData.getNumber(min, max))
  },
  getNumber(min = 0, max = 1000) {
    return Math.random() * (max - min) + min
  },
  getDateUTCTime(pastDay: 365, futureDay: 0) {
    let _current = new Date().getTime()
    let _min = _current - pastDay * 1000 * 60 * 60 * 24
    let _max = _current + futureDay * 1000 * 60 * 60 * 24
    return FakeData.getNumberInt(_min, _max)
  },
  getAge() {
    return FakeData.getNumberInt(1, 99)
  },
  getHextColor() {
    return randomHextColor()
  },
  getArrayObj(fnGetObj: any, total = 10) {
    let _arr = []
    if (fnGetObj && isFunction(fnGetObj)) {
      for (let i = 0; i < total; i++) {
        _arr.push(fnGetObj(i))
      }
    }
    return _arr
  },
  getSourceData(total = 10) {
    return FakeData.getArrayObj((index: any) => {
      return {
        Value: `${index}`,
        Text: ''
      }
    }, total)
  },
  test() {
    console.warn(FakeData)
  }
}

export default FakeData
