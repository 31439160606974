import MyComponentHelpers from '../MyComponent/Helpers'
import MyHelpers from './Helpers'
function MyTableCell(props: any) {
  const MyTypeFn = MyComponentHelpers.getMyComponentTypeFn(
    MyHelpers.Types,
    props
  )
  return MyTypeFn(props)
}

export default MyTableCell
