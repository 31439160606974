import ConfigFilter from '../configs/ConfigFilter'
import { isArray, isRegex, isString } from './Is'
const Filter = {
  _test() {
    console.warn(this)
  },
  Types: {
    select: new ConfigFilter('select', {
      typeBSTv1: 'SelectFilter'
    }),
    text: new ConfigFilter('text', {
      typeBSTv1: 'TextFilter'
    }),
    select_multi: new ConfigFilter('select_multi', {
      typeBSTv1: 'ArrayFilter'
    })
  },
  FilterBootstrapV1: {
    SelectFilter: {
      compare: 'eq'
    },
    RegexFilter: {
      compare: 'rg'
    },
    ArrayFilter: {
      compare: 'of'
    },
    CustomFilter: {
      compare: 'custom'
    },
    BetweenFilter: {
      compare: 'bw'
    }
  },
  Compare: {
    eq: {
      key: 'eq',
      fnCompare: (valueFilter: any, valueItem: any) => {
        if (valueFilter === '') {
          //filter all
          return true
        }
        return valueFilter === valueItem
      }
    },
    of: {
      key: 'of',
      fnCompare: (valueFilter: any, valueItem: any) => {
        if (isArray(valueFilter)) {
          let _valueItemString = String(valueItem)
          if (valueFilter.indexOf(_valueItemString) > -1) {
            return true
          } else {
            return false
          }
        }
        return false
      }
    },
    rg: {
      key: 'rg',
      fnCompare: (valueFilter: any, valueItem: any) => {
        if (isRegex(valueFilter)) {
          return valueFilter.test(valueItem)
        } else if (isString(valueFilter) && valueFilter.length > 0) {
          let _newRegex = new RegExp(valueFilter, 'g')
          return _newRegex.test(valueItem)
        }
        return false
      }
    },
    bw: {
      key: 'bw',
      fnCompare: (valueFilter: any, valueItem: any) => {
        if (isArray(valueFilter) && valueFilter.length > 1) {
          let _from = valueFilter[0]
          let _to = valueFilter[1]
          return (
            (valueItem >= _from || _from == null) &&
            (valueItem <= _to || _to == null)
          )
        }
        return false
      }
    }
  },
  compareTrue(
    valueFilter: any,
    valueItem: any,
    typeCompare: string,
    opts?: any
  ) {
    if (Filter.Compare[typeCompare] && Filter.Compare[typeCompare].fnCompare) {
      return Filter.Compare[typeCompare].fnCompare(valueFilter, valueItem, opts)
    }
    if (opts && opts.returnTrueWhenNoCompare === true) {
      return true
    }
    return false
  },
  compareTrueForBootstrapV1(
    type?: string,
    value?: any,
    item?: any,
    fieldName?: any,
    oneFilter?: any
  ) {
    if (type && Filter.FilterBootstrapV1[type]) {
      let _compare = Filter.FilterBootstrapV1[type].compare
      let _targetValue = item[fieldName]
      if (_compare == 'custom' && typeof value == 'object' && value.callback) {
        return value.callback(_targetValue)
      }
      return Filter.compareTrue(value, _targetValue, _compare)
    }
    if (oneFilter) {
    }
  }
}

export default Filter
