import { isNotNullOrEmpty, isObject, isString } from './Is'
import { logW } from './Log'
const HValid = {
  valid(type: string, valueCheck: any, value: any, opts: any = {}) {
    let _typeRule: string = type
    if (_typeRule && HValid.Types[_typeRule] && HValid.Types[_typeRule].check) {
      let _valueCheck = valueCheck
      if (isObject(_valueCheck)) {
        _valueCheck = _valueCheck.value
      }
      return HValid.Types[_typeRule].check(_valueCheck, value, opts)
    } else {
      logW('Missing rule of check function in rule', type, value, opts)
    }
    return false
  },
  validAllRulesOfValue(rules: any = {}, value: any) {
    let _result: any = true
    let _keys = Object.keys(rules)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        let _type = k
        let _rsType = HValid.valid(_type, rules[k], value, {})
        if (_rsType !== true) {
          if (_result === true) {
            _result = {}
          }
          _result[_type] = _rsType
        }
      }
    }
    return _result
  },
  Types: {
    required: {
      check(valueCheck: any = true, value: any, opts: any = {}) {
        if (valueCheck === true && isNotNullOrEmpty(value)) {
          return true
        }
        return {
          type: 'required',
          msg: opts.msg || 'valid failed'
        }
      }
    },
    minLength: {
      check(valueCheck: any = 0, value: any, opts: any = {}) {
        if (isString(value) && value.length > valueCheck) {
          return true
        }
        return {
          type: 'minLength',
          msg: opts.msg || 'valid failed'
        }
      }
    }
  }
}

export default HValid
