import React from 'react'
import { isArray } from 'src/helpers/Is'
import MyControl_Ext from './MyControl_Ext'
class MyControl_Ext_Select extends MyControl_Ext {
  constructor(props: any) {
    super(props)
    this.state = {
      value: null //chua thay doi gia tri
    }
    //init default
    if (
      this.props.controlOpts?.defaultCheckAll === true &&
      this.props.controlOpts.handleUpdateDefaultValues
    ) {
      let _source = this.getSourceData() || []
      let _objDefault = {
        [this.getFieldName()]: _source.map((v: any) => {
          return v.Value
        })
      }
      console.warn('handleUpdateDefaultValues:', _source, _objDefault)
      this.props.controlOpts.handleUpdateDefaultValues(_objDefault)
      this.forceUpdate()
      var a =
        this.props.controlOpts.getDefaultValues &&
        this.props.controlOpts.getDefaultValues()
      console.warn('handleUpdateDefaultValues after:', a)
    }
  }
  getDefaultValueWhenAllThingNull = () => {
    return []
  }
  getThisValueByCheckValid = (value: any) => {
    if (isArray(value)) {
      return value
    }
    return this.getDefaultValueWhenAllThingNull()
  }
  getMulti = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.multi === false) {
      return false
    }
    return true //default true
  }
  getInLine = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.inline === true) {
      return true
    }
    return false //default false
  }
  getSourceList = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.getSourceList) {
      return controlOpts.getSourceList()
    }
    return {}
  }
  getSourceData = () => {
    const { controlOpts } = this.props
    if (controlOpts) {
      if (controlOpts.sourceData) {
        return controlOpts.sourceData
      } else if (controlOpts.sourceKey && controlOpts.getSourceList) {
        return this.getSourceList[controlOpts.sourceKey]
      }
    }
    return []
  }
  getAllSelected = () => {
    const thisValue = this.getThisValue()
    let _sourceData = this.getSourceData()
    if (
      thisValue !== null &&
      thisValue.length > 0 &&
      _sourceData &&
      _sourceData.length > 0
    ) {
      return _sourceData.filter((v: any) => {
        return this.isChecked(v)
      })
    }
    return []
  }
  isChecked = (option: any) => {
    const thisValue = this.getThisValue()
    if (thisValue !== null) {
      if (thisValue.indexOf(option.Value) > -1) {
        return true
      }
    }
    return false
  }
  clearAll = () => {
    this.changeValue([], () => {})
  }
  selectAll = () => {
    let _sourceData = this.getSourceData()
    let _value = []
    if (_sourceData && _sourceData.length > 0) {
      for (let i = 0; i < _sourceData.length; i++) {
        _value.push(_sourceData[i].Value)
      }
    }
    this.changeValue(_value, () => {})
  }
  onCheckOption = (option: any) => {
    let _value = this.getThisValue()
    const isMulti = this.getMulti()
    console.warn('onCheckOption:', isMulti, _value)
    if (isMulti === false) {
      _value = [option.Value]
    } else {
      if (this.isChecked(option)) {
        let _index = _value.indexOf(option.Value)
        _value.splice(_index, 1)
      } else {
        _value.push(option.Value)
      }
    }
    this.changeValue(_value, () => {})
  }
  render() {
    return <div></div>
  }
}

export default MyControl_Ext_Select
