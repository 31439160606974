import MyComponentTypes from '../MyComponent/Types'
const MyLibHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyLibHelpers.Types, key, fnUI)
  },
  addTypesFromListLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListLazy(
      MyLibHelpers.Types,
      listWithKey
    )
  },
  addTypesFromListWithoutLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListWithoutLazy(
      MyLibHelpers.Types,
      listWithKey
    )
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  }
}

export default MyLibHelpers
