import MyComponentTypes from '../MyComponent/Types'
interface IConfigDetail {
  controller?: string
  queryFromParent?: any
  screenCode?: string
  title?: string
  titleField?: string
  bgColor?: string
  bgF?: string
  needReloadMaster?: boolean
  needReloadMasterAfterAddNew?: boolean
  needReloadMasterAfterUpdateField?: boolean
  needReloadMasterAfterDeleteItem?: boolean
  needReloadMasterAfterList?: boolean
  useOptionsInMaster?: boolean
  userOptionsInMaster?: boolean //sai chinh ta
  prefixFieldKeyUI?: any
  isRow?: boolean //trinh bay detail dang row
}
const MyPageHelpers = {
  setDefaultMyPage(fnUI: any) {
    MyPageHelpers.Types['_default'] = fnUI
  },
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyPageHelpers.Types, key, fnUI)
  },
  addTypesFromListLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListLazy(
      MyPageHelpers.Types,
      listWithKey
    )
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  },
  buildConfigPageFromConfigDetail(configDetail: IConfigDetail, row: any) {
    if (configDetail.controller) {
      let _title = 'Detail'
      if (configDetail.title) {
        _title = configDetail.title
      } else if (configDetail.titleField && row[configDetail.titleField]) {
        _title = row[configDetail.titleField]
      }
      let _requestData = {}
      if (configDetail.queryFromParent) {
        let _keys = Object.keys(configDetail.queryFromParent)
        if (_keys && _keys.length > 0) {
          for (let k of _keys) {
            let _fieldName = configDetail.queryFromParent[k]
            if (row.hasOwnProperty(_fieldName)) {
              _requestData[k] = row[_fieldName]
            } else {
              _requestData[k] = configDetail.queryFromParent[k]
            }
          }
        }
      }
      return {
        APIName: configDetail.controller,
        Config: '',
        Id: '',
        RequestData: _requestData,
        ScreenCode: configDetail.screenCode,
        Title: _title,
        Type: '',
        UIType: '',
        UIUrl: ''
      }
    }
    return null
  }
}

export default MyPageHelpers
