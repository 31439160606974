import React from 'react'

//mac dinh se co
//dau vao
//dau ra
interface IPropsControlOpts {
  onUpdateValue?: any
  fieldName?: any
  format?: any
  defaultCheckAll?: boolean
  handleUpdateDefaultValues?: any
  getDefaultValues?: any
  multi?: boolean
  inline?: boolean
  sourceData?: any
  getSourceList?: any
  sourceKey?: string
  configPagination?: any //config phan trang neu co su dung list
  dataType?: string //Dung cho kieu array, nhung muon nhan string
  charSeparator?: string //Dung cho kieu array, nhung muon nhan string, mac dinh la ,
  configSearch?: any //config search khi can dung search trong control
}
interface IProps {
  defaultValue?: any
  value?: any
  controlOpts?: IPropsControlOpts | any
}
class MyControl_Ext extends React.Component<IProps, any> {
  getDefaultValue = () => {
    return this.props.defaultValue
  }
  getDefaultValueWhenAllThingNull: any = () => {
    return ''
  }
  // onUpdateValue = () => {}
  getThisValue = () => {
    let _value = this.getDefaultValueWhenAllThingNull()
    if (this.getIsUnControlled()) {
      _value = this.state.value
    } else {
      _value = this.props.value
    }
    return this.getThisValueByCheckValid(_value)
  }
  getThisValueByCheckValid: any = (value: any) => {
    return value
  }
  getIsUnControlled = () => {
    if (this.props.hasOwnProperty('value')) {
      return false
    }
    return true
  }
  changeValue = (value: any, cb: any) => {
    const onUpdateValue = this.getOnUpdateValue()
    if (this.getIsUnControlled()) {
      this.setState(
        {
          value: value
        },
        () => {
          if (onUpdateValue) {
            onUpdateValue(value)
          }
          if (cb) {
            cb(this.state.value)
          }
        }
      )
    } else {
      if (onUpdateValue) {
        onUpdateValue(value)
      } else {
        console.warn('Missing onUpdateValue when controlled control')
      }
    }
  }
  //get from controlOpts
  getControlOpts = () => {
    const { controlOpts } = this.props
    return controlOpts || {}
  }
  getFieldName = () => {
    const controlOpts = this.getControlOpts()
    return controlOpts.fieldName || ''
  }
  getFormat = () => {
    const controlOpts = this.getControlOpts()
    return controlOpts.format
  }
  getOnUpdateValue = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.onUpdateValue) {
      return controlOpts.onUpdateValue
    }
    return () => {}
  }
  render() {
    return <div></div>
  }
}

export default MyControl_Ext
