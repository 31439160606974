import * as React from 'react'
import ReactDOMServer from 'react-dom/server'
import MyIcon from 'src/components/MyIcon'
const HComponent = {
  getHtmlFromConfigComponent: (item: any) => {
    if (item) {
      if (item.myIcon) {
        let _moreProps: any = {}
        if (item.className) {
          _moreProps.className = item.className
        }
        if (item.style) {
          _moreProps.style = item.style
        }
        let _component = <MyIcon configIcon={item.myIcon} {..._moreProps} />
        return ReactDOMServer.renderToStaticMarkup(_component)
      }
    }
    return ''
  }
}

export default HComponent
