import * as React from 'react'
import { logNoAnyType } from 'src/helpers/Log'
const OneHelpers = {
  getUIFn(type: any, MyHelpers: any, nameOne: string) {
    if (type && MyHelpers && MyHelpers.Types) {
      if (MyHelpers.Types[type]) {
        return MyHelpers.Types[type]
      } else {
        logNoAnyType(`${nameOne}:${type}`, this)
      }
    }
    return MyHelpers.Types._empty
  },
  getUIWrapFn(type: any, MyHelpers: any, nameOne: string) {
    if (type && MyHelpers && MyHelpers.TypesWrap) {
      if (MyHelpers.TypesWrap[type]) {
        return MyHelpers.TypesWrap[type]
      } else {
        logNoAnyType(`${nameOne}:${type}`, this)
      }
    }
    if (MyHelpers.TypesWrap._default) {
      return MyHelpers.TypesWrap._default
    }
  },
  render(props: any, one: any, nameOne: string) {
    if (one && one.getUIFn) {
      let ComponentWrap = null
      const Component = one.getUIFn()
      if (one.getUIWrapFn) {
        ComponentWrap = one.getUIWrapFn()
      }
      // console.warn('render:', ComponentWrap, Component)
      if (ComponentWrap) {
        return (
          <ComponentWrap {...{ [nameOne || 'one']: one }} {...props}>
            <Component {...{ [nameOne || 'one']: one }} {...props} />
          </ComponentWrap>
        )
      }
      return <Component {...{ [nameOne || 'one']: one }} {...props} />
    }
    return <div></div>
  }
}

export default OneHelpers
