const Constant = {
  DefaultUITypeWhenNull: 'Table',
  Key: {
    Table: 'Table',
    Header: 'Header',
    Page: 'Page',
    Detail: 'Detail',
    Filter: 'Filter',
    //
    Type: 'Type'
  },
  Array: {
    colors: ['success', 'danger', 'primary', 'secondary', 'link', 'warning']
  },
  AppConfig: {
    showClassFieldOnCell: 'showClassFieldOnCell'
  }
}

export default Constant
