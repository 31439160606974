import React from 'react'
import CSuppense from './CSuppense'
import { logExistType } from '../../helpers/Log'

const MyComponentTypes = {
  addTypesGeneric(classMy: any, key: string, fnUI: any) {
    if (classMy && key) {
      let _key = key.toLocaleLowerCase()
      if (classMy[_key]) {
        logExistType(_key)
      } else {
        classMy[_key] = fnUI
      }
    }
  },
  addTypesGenericFromListLazy(classMy: any, listWithKey: any) {
    if (listWithKey) {
      let _keys = Object.keys(listWithKey)
      if (_keys && _keys.length > 0) {
        for (let k of Object.keys(listWithKey)) {
          MyComponentTypes.addTypesGeneric(classMy, k, (props: any) => {
            return CSuppense(props, listWithKey[k])
          })
        }
      }
    }
  },
  addTypesGenericFromListWithoutLazy(classMy: any, listWithKey: any) {
    if (listWithKey) {
      let _keys = Object.keys(listWithKey)
      if (_keys && _keys.length > 0) {
        for (let k of Object.keys(listWithKey)) {
          MyComponentTypes.addTypesGeneric(classMy, k, (props: any) => {
            const Tag = listWithKey[k]
            return <Tag {...props} />
          })
        }
      }
    }
  },
  removeTypeGeneric(classMy: any, key: string) {
    if (classMy && key) {
      let _key = key.toLocaleLowerCase()
      if (classMy[_key]) {
        delete classMy[_key]
      }
    }
  },
  Types: {
    _empty: () => {
      return <div></div>
    }
  },
  TypesWrap: {
    _empty: (props: any) => {
      return props.children
    }
  }
}

export default MyComponentTypes
