import { json2Obj } from './Json'

const More = {
  Cell: {
    Key: {
      fCanShow: 'fCanShow',
      hideOfIds: 'hideOfIds'
    },
    getConfigFromMore({ extraOfColumn, more, key, df }: any = {}) {
      let _more = more || More.Cell.getMore(extraOfColumn) || {}
      if (_more.hasOwnProperty(key)) {
        return _more[key]
      }
      return df
    },
    getMore(extraOfColumn: any, df: any = {}) {
      let _more = df
      if (extraOfColumn.Type) {
        if (extraOfColumn.Type.type) {
          _more = extraOfColumn.Type.type
        }
        if (extraOfColumn.Type.more) {
          _more = json2Obj(extraOfColumn.Type.more, {})
        }
      }
      return _more
    }
  }
}

export default More
