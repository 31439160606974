import MyComponentTypes from '../MyComponent/Types'
const MyTableCellHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyTableCellHelpers.Types, key, fnUI)
  },
  addTypesFromListLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListLazy(
      MyTableCellHelpers.Types,
      listWithKey
    )
  },
  addTypesWrap(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyTableCellHelpers.TypesWrap, key, fnUI)
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  },
  TypesWrap: {
    _empty: MyComponentTypes.TypesWrap._empty
  }
  //more
}

export default MyTableCellHelpers
