import * as React from 'react'
import MyComponentHelpers from '../MyComponent/Helpers'
import MyHelpers from './Helpers'
function MyLib(props: any) {
  const MyTypeFn = MyComponentHelpers.getMyComponentTypeFn(
    MyHelpers.Types,
    props
  )
  return <MyTypeFn {...props} />
}

export default MyLib
