import * as React from 'react'
import { isString } from 'src/helpers/Is'
import MyComponentHelpers from '../MyComponent/Helpers'
import MyHelpers from './Helpers'
function MyIcon(props: any) {
  const configIcon = props.configIcon || props.data || {}
  let _type = props.type
  const style = props.style || {}
  const className = props.className || {}
  if (configIcon && isString(configIcon)) {
    if (configIcon.startsWith('fa')) {
      return <i className={configIcon} style={style} />
    } else if (configIcon.startsWith('type:')) {
      _type = configIcon.replace('type:', '')
    } else if (configIcon.startsWith('data:')) {
      return <img className={className} src={configIcon} style={style} />
    } else if (configIcon.startsWith('http') || configIcon.startsWith('/')) {
      return <img className={className} src={configIcon} style={style} />
    }
  }
  if (_type) {
    const MyTypeFn = MyComponentHelpers.getMyComponentTypeFn(MyHelpers.Types, {
      type: _type,
      ...props
    })
    return MyTypeFn(props)
  }
  return <div></div>
}

export default MyIcon
