import * as HLocalStorage from './LocalStorage'

export function xoaDauInText(s: string) {
  let str = s + '' //phai chac chan la chuoi
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export function removeSpace(s: string) {
  if (s != null) {
    s = s + '' //phai chac chan la chuoi
    return s.replace(/\s/g, '')
  }
  return ''
}
export function removeSpecialChars(s: string) {
  if (s != null) {
    s = s + '' //phai chac chan la chuoi
    s = s.replace(/[;-=!@^&\/\\#,+()$~%.'":*?<>{}]/g, '')
    return s
  }
  return ''
}
export function validSearchTextTV(sourceText: string, searchText: string) {
  if (sourceText && searchText != null) {
    let _source = xoaDauInText(sourceText).toLocaleLowerCase()
    let _search = xoaDauInText(searchText).toLocaleLowerCase()
    if (_source.indexOf(_search) > -1) {
      return true
    }
  }
  return false
}

export function validSearchText(
  sourceText: string,
  searchText: string,
  configSearch?: any
) {
  if (configSearch && configSearch.noSymbol) {
    return validSearchTextTV(sourceText, searchText)
  }
  if (sourceText && searchText != null) {
    let _source = sourceText.toLocaleLowerCase()
    let _search = searchText.toLocaleLowerCase()
    if (_source.indexOf(_search) > -1) {
      return true
    }
  }
  return false
}

export function test() {
  console.warn('')
}

const ConfigText = {
  Data: {},
  DataByLangCode: {},
  defaultLang: 'vi',
  currentLang: 'vi',
  kLastLangCode: '_ckLastLangCode'
}

export function initText(configText: {
  defaultLang?: string
  currentLang?: string
  DataByLangCode: any
}) {
  if (configText.defaultLang) ConfigText.defaultLang = configText.defaultLang
  if (configText.currentLang) {
    ConfigText.currentLang = configText.currentLang
  } else {
    ConfigText.currentLang = getLastCurrentLangCode()
  }
  if (configText.DataByLangCode) {
    for (let k of Object.keys(configText.DataByLangCode)) {
      ConfigText.DataByLangCode[k] = configText.DataByLangCode[k]
    }
  }
  if (ConfigText.DataByLangCode[ConfigText.currentLang]) {
    ConfigText.Data = ConfigText.DataByLangCode[ConfigText.currentLang]
  }
}

export function changeLang(langcode: string) {
  if (Object.keys(ConfigText.DataByLangCode).indexOf(langcode) > -1) {
    ConfigText.currentLang = langcode
    ConfigText.Data = ConfigText.DataByLangCode[ConfigText.currentLang]
    HLocalStorage.saveString(ConfigText.kLastLangCode, langcode)
  }
}

export function addLangByCode(langcode: string, dataLang: any) {
  ConfigText.DataByLangCode[langcode] = dataLang || {}
}

export function getLastCurrentLangCode() {
  return HLocalStorage.getString(
    ConfigText.kLastLangCode,
    ConfigText.defaultLang
  )
}

export function get(key: string) {
  if (key && ConfigText.Data[key]) {
    return ConfigText.Data[key]
  } else {
    console.warn('Text key not exist:', key)
  }
  return ''
}

export function getMulti(arrayKey: string[]) {
  let _s = ''
  if (arrayKey) {
    for (let i = 0; i < arrayKey.length; i++) {
      let _k = arrayKey[i]
      let _ss = get(_k)
      if (_ss) {
        if (i > 0) {
          _s += ' '
        }
        _s += _ss
      }
    }
  }
  return _s
}

//su dung trong globalsetting
export function getTextDisplaySettings() {
  let _list = []
  let _keys = Object.keys(ConfigText.Data)
  for (let i in _keys) {
    _list.push({
      Key: _keys[i],
      Value: ConfigText.Data[_keys[i]]
    })
  }
  return _list
}

export function updateFromList(list: any) {
  for (let i in list) {
    let _item = list[i]
    ConfigText.Data[_item.Key] = _item.Value
  }
}

//for search

export function buildTextForSearch(s: string) {
  let _s = removeSpace(s)
  _s = removeSpecialChars(_s)
  _s = xoaDauInText(_s)
  _s = _s.toLowerCase()
  return _s
}

export function compareTextForSearch(
  text: string,
  textSearch: string,
  opts: { searchKoDau?: boolean } = {}
) {
  if (text && textSearch) {
    if (opts.searchKoDau === true) {
      let _text = buildTextForSearch(text)
      let _textSearch = buildTextForSearch(textSearch)
      if (new RegExp(_textSearch, 'gi').test(_text) === true) {
        return true
      }
    }
    if (new RegExp(textSearch, 'gi').test(text) === true) {
      return true
    }
  }
  return false
}
