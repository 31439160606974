import { isNotNullOrEmpty, isNullOrEmpty, isObject } from './Is'

interface IConfigFormat {
  type?: string
  format?: string
  thousandSeparator?: string
  decimalSeparator?: string
  decimals?: number
  currencyChar?: string
  currencyPos?: 'last' | 'first'
  currencyThousandSeparator?: string
  currencyDecimalSeparator?: string
  date?: string
  datetime?: string
  time?: string
  switch?: any //kiem tra value theo gia tri
  compare?: any
}
const Format = {
  defaultConfig: {
    thousandSeparator: ' ',
    decimalSeparator: '.',
    decimals: 2, //number decimal
    currencyChar: '$',
    currencyPos: 'first', //last,first
    currencyThousandSeparator: '.',
    currencyDecimalSeparator: ',',
    date: 'd/m/Y',
    datetime: 'd/m/Y HH:ss',
    time: 'HH:ss'
  },
  loadConfigByLang(config: any) {
    Format.defaultConfig = { ...Format.defaultConfig, ...config }
  },
  format(value: any = '', configFormat: IConfigFormat = {}, opts: any = {}) {
    if (
      configFormat.type &&
      Format.Types[configFormat.type] &&
      Format.Types[configFormat.type]['format']
    ) {
      return Format.Types[configFormat.type]['format'](
        value,
        configFormat,
        opts
      )
    }
  },
  compare(value: any, compareValue: any, operator: string = '==') {
    switch (operator) {
      case '==':
        return value == compareValue
      case '>=':
        return value >= compareValue
      case '<=':
        return value <= compareValue
      case '===':
        return value === compareValue
      case '>':
        return value > compareValue
      case '<':
        return value < compareValue
      case '!=':
        return value != compareValue
      case '!==':
        return value !== compareValue
      case 'isEmpty':
        return isNullOrEmpty(value)
      case 'isNotEmpty':
        return isNotNullOrEmpty(value)
    }
    return false
  },
  Types: {
    compare: {
      format(value: any, configFormat: IConfigFormat = {}, opts: any = {}) {
        let _result: any = ''
        if (configFormat.compare && configFormat.compare.result != null) {
          if (configFormat.compare._default) {
            _result = configFormat.compare._default
          }
          let _compareValue = null
          if (
            configFormat.compare.fieldName &&
            opts &&
            opts.data &&
            opts.data.hasOwnProperty(configFormat.compare.fieldName)
          ) {
            _compareValue = opts.data[configFormat.compare.fieldName]
          } else if (configFormat.compare.compareValue) {
            _compareValue = configFormat.compare.compareValue
          }
          if (
            Format.compare(value, _compareValue, configFormat.compare.operator)
          ) {
            _result = configFormat.compare.result
          }
          if (isObject(_result) && _result.fieldName) {
            if (opts && opts.data && opts.data[_result.fieldName]) {
              _result = opts.data[_result.fieldName]
            } else {
              _result = ''
            }
          }
        }
        return _result
      }
    },
    switch: {
      format(
        value: any = '',
        configFormat: IConfigFormat = {},
        opts: any = {}
      ) {
        let _result: any = ''
        if (configFormat.switch) {
          if (configFormat.switch._default) {
            _result = configFormat.switch._default
          }
          if (configFormat.switch._notEmpty) {
            if (isNotNullOrEmpty(value)) {
              _result = configFormat.switch._notEmpty
            }
          }
          if (configFormat.switch._isEmpty) {
            if (isNullOrEmpty(value)) {
              _result = configFormat.switch._isEmpty
            }
          }
          if (configFormat.switch[value] != null) {
            _result = configFormat.switch[value]
          }
          if (isObject(_result) && _result.fieldName) {
            if (opts && opts.data && opts.data[_result.fieldName]) {
              _result = opts.data[_result.fieldName]
            } else {
              _result = ''
            }
          }
          return _result
        }
        return ''
      }
    },
    money: {
      format(value: any = '', configFormat: IConfigFormat = {}) {
        return value || configFormat || ''
      }
    },
    money_vnd: {
      format(value: any = '', configFormat: IConfigFormat = {}) {
        let _config = {
          ...{ decimals: 0, decimalSeparator: '', thousandSeparator: '.' },
          ...configFormat
        }
        return Format.Types.number.format(value, _config)
      }
    },
    number: {
      format(value: any = '', configFormat: IConfigFormat = {}) {
        let _number = Number(value)
        if (_number !== NaN) {
          let _config = { ...Format.defaultConfig, ...configFormat }
          return number_format(
            _number,
            _config.decimals,
            _config.decimalSeparator,
            _config.thousandSeparator
          )
        }
        return value || configFormat || ''
      }
    }
  }
}

export const number_format = function (
  number: number,
  decimals: number,
  dec_point: string,
  thousands_sep: string
) {
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    toFixedFix = function (n: number, prec: number) {
      var k = Math.pow(10, prec)
      return Math.round(n * k) / k
    },
    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

export default Format
